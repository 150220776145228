import {
	useCallback, useEffect, useMemo, useRef, useState 
} from "react";

import { useRouter } from "next/router";

import { useFunnelStore } from "@/store/FunnelStore";
import { useUserDataStore } from "@/store/UserDataStore";
import { FunnelData, Pet } from "@/types/pet";

export const useFunnelStepHelper = () => {
	const [user, removeUserPets] = useUserDataStore((state) => [state.user, state.removeUserPets,]);

	const [petsWithRecipesAvailable, setPetsWithRecipesAvailable] = useState<
		Pet[]
	>([]);

	const [petsNoRecipesAvailable, setPetsWithNoRecipesAvailable] = useState<
		Pet[]
	>([]);

	const [allPetsWithRecipesAvailable, setAllPetsWithRecipesAvailable] =
		useState<Pet[]>([]);

	const [activePets, setActivePets] = useState<Pet[]>([]);

	const hasSetInitialFunnelData = useRef(false);

	const [
		setFunnelDataFromLocalStorage,
		initialisePet,
		funnelData,
		setIteration,
		funnelIteration,
		resetFunnelData,
	] = useFunnelStore((state) => [
		state.setFunnelDataFromLocalStorage,
		state.initialisePet,
		state.data,
		state.setIteration,
		state.iteration,
		state.resetFunnelData,
	]);

	const { push, query } = useRouter();

	const { iteration = "1" } = query;

	const convertUserPetToFunnelPet = useCallback((pet: Pet) => {
		return {
			activity: pet.activity,
			adultAge: pet.adultAge,
			adultWeight: pet.adultWeight,
			allergies: (pet.allergies?.length || 0) < 1
				? ["none"]
				: pet.allergies,
			behavioural: pet.behavioural,
			breed: pet.breed,
			breedCount: pet.breedCount,
			coat: pet.coat,
			diet: pet.diet,
			digestion: pet.digestion,
			fussy: pet.fussy,
			gender: pet.gender,
			goal: pet.goal,
			estimatedWeight: undefined,
			healthIssues:
				(pet.healthIssues?.length || 0) < 1
					? ["none"]
					: pet.healthIssues,
			improveHealth: pet.improveHealth,
			lifestage: pet.lifestage,
			mobility: pet.mobility,
			months: pet.age - Math.floor(pet.age / 12) * 12,
			name: pet.name,
			neutered: pet.neutered
				? "yes"
				: "no",
			oldPuppyAge: pet.oldPuppyAge || undefined,
			oral: pet.oral,
			pronouns: pet.pronouns,
			shape: pet.shape,
			stools: pet.stools,
			transformedAge: pet.age,
			treats: pet.treatsFed,
			weight: pet.weight,
			workingDog: pet.workingDog,
			years: Math.floor(pet.age / 12) > 0
				? Math.floor(pet.age / 12)
				: 0,
			petId: pet.petId,
		} as FunnelData;
	}, []);

	const setFunnelDataFromPet = useCallback(
		(pet: Pet) => {
			initialisePet(pet.name, convertUserPetToFunnelPet(pet));
		},
		[convertUserPetToFunnelPet, initialisePet]
	);

	// If the iteration in the funnel doesn't match the iteration in the query string
	useEffect(() => {
		if (hasSetInitialFunnelData.current) {
			return;
		}

		const iterationDoesntMatch = `${funnelIteration}` !== `${iteration}`;

		const iterationMatches = !iterationDoesntMatch;

		if (iterationMatches && funnelData.length === 0) {
			setFunnelDataFromLocalStorage();
			hasSetInitialFunnelData.current = true;

			return;
		}
	}, [
		funnelData.length,
		funnelIteration,
		iteration,
		setFunnelDataFromLocalStorage,
		setFunnelDataFromPet,
		setIteration,
		user.pets,
	]);

	const removePetFromUser = useCallback(
		(newData: string[]) => {
			removeUserPets(newData);
		},
		[removeUserPets]
	);

	const updatePetFromForm = useCallback((_newData: Partial<Pet>) => {
		console.log("Deprecated function called", "updatePetFromForm");
	}, []);

	const goToNextIteration = useCallback(() => {
		if (!user.pets) {
			return;
		}

		
		resetFunnelData();
		push({
			pathname: "./signup",
			query: {
				iteration: parseInt(iteration as string) + 1,
			},
		});

		return;
		

	}, [
		iteration,
		push,
		resetFunnelData,
		user.pets
	]);

	useEffect(() => {
		if (!user.pets) {
			return;
		}

		// Do all of this in one use effect, less renders overall and quicker to run.

		const currentIterationPets = Object.values(user.pets)
			.reduce((list, pet) => {
				if (`${pet.iteration}` === `${iteration}`) {
					return [...list, pet];
				}

				return list;
			}, [] as Pet[])
			.sort(({ index: a, index: b }) => a - b);

		setActivePets(currentIterationPets);

		setPetsWithNoRecipesAvailable(
			currentIterationPets &&
				currentIterationPets.filter(
					({ shuffledRecipes }) => shuffledRecipes && !shuffledRecipes.length
				)
		);

		setPetsWithRecipesAvailable(
			currentIterationPets &&
				currentIterationPets.filter(
					({ shuffledRecipes }) => shuffledRecipes && !!shuffledRecipes.length
				)
		);

		const petValues = Object.values(user.pets);

		const allPets = petValues
			.filter(
				({ shuffledRecipes }) => shuffledRecipes && !!shuffledRecipes.length
			)
			?.reduce((list, pet) => {
				return [...list, pet];
			}, [] as Pet[])
			.sort(
				({ iteration: a, index: c }, { iteration: b, index: d }) =>
					parseFloat(`${a}.${c}`) - parseFloat(`${b}.${d}`)
			);

		setAllPetsWithRecipesAvailable(allPets || []);
	}, [iteration, user.pets]);

	const puppies = activePets.filter(
		({ lifestage }) => lifestage === "young puppy" || lifestage === "old puppy"
	);

	const multiplePuppies = useMemo(() => puppies.length > 1, [puppies.length]);
	const onePuppy = useMemo(() => puppies.length === 1, [puppies.length]);

	return {
		updatePetFromForm,
		activePets,
		currentIteration: iteration,
		multiplePuppies,
		onePuppy,
		puppies,
		petsNoRecipesAvailable,
		petsWithRecipesAvailable,
		removePetFromUser,
		allPetsWithRecipesAvailable,
		goToNextIteration,
		setFunnelDataFromPet,
	};
};
